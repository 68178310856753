.javanese_font {
    font-family: 'Javanese Text';
}

@font-face {
    font-family: "Poppins";
    src: url(../src/assets/font/Poppins-Regular.ttf);
  }
  
  @font-face {
    font-family: "Poppins-Light";
    src: url(../src/assets/font/Poppins-Light.ttf);
  }
  
  .poppins{
    font-family: "Poppins";
  }
  
  .poppins-Light{
    font-family: "Poppins-Light";
  }
  